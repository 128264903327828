
export const simpleMatch = (value: string, toMatch: string) => {
  let iterator = 0;
  for (let i = 0; i < value.length; i++) {
    iterator =
      value[i].toLocaleLowerCase() === toMatch[iterator].toLowerCase() ? iterator + 1 : 0;
    if (iterator === toMatch.length)
      return true;
  }
  return false;
}

export const generateWhiteSpace = (count: number) => {
  return "\u00A0".repeat(count);
}
