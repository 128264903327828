import { CompanyDescription } from '../common/Company/CompanyDescription'
import { DevelopedFeatures } from '../common/Company/DevelopedFeatures'
import { ItemTitle } from '../common/Section/ItemTitle'

export const WEMANITY = () => {
    return (
        <>
            <ItemTitle>Développeur Front & Back - React, NestJS</ItemTitle>
            <CompanyDescription name='WEMANITY' date='2022 2023 - 6 mois' location='Paris'>
                Wemanity est un groupe de conseil unique qui aide les entreprises à
                construire et faire vivre leurs organisations au service de leurs clients, de
                façon agile, performante et responsable.
            </CompanyDescription>
            <DevelopedFeatures>
                <span>
                    Développement d’un serveur de gestion de notes conteneurisé en
                    architecture hexagonale (Nest, Docker)
                </span>
                <span>
                    Développement d’une application de gestion de notes (React)
                </span>
            </DevelopedFeatures>
        </>
    )
}
