import { CompanyDescription } from '../common/Company/CompanyDescription'
import { DevelopedFeatures } from '../common/Company/DevelopedFeatures'
import { ItemTitle } from '../common/Section/ItemTitle'

export const PMU = () => {
    return (
        <>
            <ItemTitle>Développeur Front - React Native</ItemTitle>
            <CompanyDescription name='PMU' date='2023 - 1 mois' location='Paris'>
                Le PMU (Pari Mutuel Urbain) est une entreprise Française qui se spécialise
                dans les paris hippiques mutualisés.
            </CompanyDescription>
            <DevelopedFeatures>
                <span>
                    Implémentation d’un loader (partie course)
                </span>
                <span>
                    Correction de bugs en collaboration avec l’équipe course.
                </span>
            </DevelopedFeatures>
        </>
    )
}
