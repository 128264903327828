import './ProjectsTitle.scss';

interface ProjectsTitleProps {
    children: React.ReactElement | string,
}

export const ProjectsTitle = ({ children }: ProjectsTitleProps) => {
    return (
        <div id='projectsTitle' className='projects_title'>
            <span className='projects_title_h1'>
                {children}
            </span>
        </div>
    )
} 