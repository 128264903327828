import { navIcons } from "../../iconPaths";
import './SvgSearchIcon.scss';

interface SvgSearchIconProps {
    style: React.CSSProperties;
    onClick: () => void;
}

export const SvgSearchIcon = (({ style, onClick }: SvgSearchIconProps) => {
    const SearchIcon = (props: React.SVGProps<SVGSVGElement>) => navIcons['search']({
        className: 'searchbar_svg',
        fill: 'currentColor',
        focusable: 'false',
        height: '50',
        width: '50',
        ...props,
    });
    return (
        <div className='searchbar_svg_container' style={{ width: 'auto' }} onClick={onClick}>
            <SearchIcon style={style} />
        </div>);
}
);