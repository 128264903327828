import { projectType } from '../../pages/Projects/ProjectsPage/ProjectsPage';
import './ProjectVideo.scss';

type Props = {
  project: projectType;
}

export const ProjectVideo = ({ project }: Props) => {
  return (
    <div className='project_video_container'>
        <iframe
          className='project_video_media'
          title={project.title}
          src={project.videoUrl}
          allowFullScreen
        />
    </div>
  )
}
