import "./Header.scss";
import { Presentation } from "./Presentation/Presentation";

interface HeaderProps {
    scrollTo?: string;
}

export const Header = ({ scrollTo }: HeaderProps) => {
    return (
        <Presentation scrollTo={scrollTo ?? '#'} />
    );
}