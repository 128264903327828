
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomAutocomplete } from '../../../MUIWrapper/Autocomplete/CustomAutocomplete';
import { OkButton } from '../../../navigation/ValidationButton/OkButton';
import { SvgSearchIcon } from '../../../svgs/SvgSearchIcon';
import { projectType } from './ProjectsPage';
import { ProjectsTitle } from './ProjectsTitle';
import './SearchBar.scss';

interface SearchBarProps {
  projects: projectType[];
}

export const SearchBar = ({ projects }: SearchBarProps) => {
  const navigate = useNavigate();
  const adjacentElementToSvgRef = useRef<HTMLDivElement>(null);
  const [searchedProject, setSearchedProject] = useState<string>('');

  const handleSearchInputChange = (value: { label: string; }) => {
    setSearchedProject(value.label);
  }

  const handleSearchValidation = (key?: React.KeyboardEvent<HTMLDivElement>) => {
    if (key?.code === 'Enter' || !key) {
      navigate(`/projects/${searchedProject}`);
    }
  }

  const handleSvgClick = () => {
    navigate(`/projects/${searchedProject}`);
  }

  return (
    <div className='searchbar_container'>
      <div style={{flex: 1}}>
        <ProjectsTitle>Mes réalisations</ProjectsTitle>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CustomAutocomplete
            ref={adjacentElementToSvgRef}
            projects={projects}
            handleAutocompleteChange={handleSearchInputChange}
            handleAutocompleteValidation={handleSearchValidation}
          />
          <SvgSearchIcon style={{ height: 'auto' }} onClick={handleSvgClick} />
          <OkButton
            displayCondition={searchedProject.length > 0}
            onClickHandler={handleSearchValidation}
          >
            Ok
          </OkButton>
        </div>
      </div>
    </div>
  )
}
