import { CompanyDescription } from "../common/Company/CompanyDescription"
import { CompanyLink } from "../common/Company/CompanyLink"
import { DevelopedFeatures } from "../common/Company/DevelopedFeatures"
import { ItemTitle } from "../common/Section/ItemTitle"

export const AUTOENTREPRISE = () => {
    return (
        <>
            <ItemTitle>Développeur indépendant</ItemTitle>
            <CompanyDescription
                name={<CompanyLink title='AUTO-ENTREPRENEUR' link='https://www.olivier-laffon.com' />}
                date='2021 2022 - 1 an'
                location='Le Kremlin-Bicêtre'
            >
                En tant qu'auto entrepreneur, j'apporte des solutions techniques aux
                problèmes rencontré par les clients.
            </CompanyDescription>
            <DevelopedFeatures>
                <span>
                    Développement d’une application de type CMS (React)
                </span>
                <span>
                    Mise en place d’une API de génération de contenu (Strapi)
                </span>
                <span>
                    Développement de la communication API / application React, Node, QS
                </span>
                <span>
                    Génération d’une solution hors ligne avec synchronisation délayée
                    du service (Tauri)
                </span>
            </DevelopedFeatures>
        </>)
}
