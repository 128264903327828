import DOMPurify from "dompurify";
import { marked } from "marked";
import { useEffect, useMemo, useRef, useState } from "react";
import { TypewriterEffect } from "../../../animated/TypeWriter/TypewriterEffect";
import { projectType } from "../../../pages/Projects/ProjectsPage/ProjectsPage";
import '../ProjectCardStack/ProjectCardStack.scss';
import './ProjectCardPreview.scss';

interface ProjectCardPreviewProps {
    project: projectType;
    typewriting?: boolean;
    delay?: number;
    autoScroll?: boolean;
    style?: React.CSSProperties;
}

export const ProjectCardPreview = ({ project, typewriting = false, delay = 75, autoScroll = false, style }: ProjectCardPreviewProps) => {
    const [markedContext, setMarkedContext] = useState('');
    const [autoScrollState, setAutoScrollState] = useState(autoScroll);

    const scrollRef = useRef<HTMLDivElement>(null);


    const memoizedMarkup = useMemo(() => ({
        __html: markedContext
    }), [markedContext]);

    useEffect(() => {
        if (autoScrollState) {

            const observer = new MutationObserver(() => {
                if (scrollRef.current) {
                    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
                }
            });

            if (scrollRef.current) {
                observer.observe(scrollRef.current, {
                    childList: true, // Surveille les changements d'enfants
                    subtree: true, // Surveille les sous-éléments
                    characterData: true, // Surveille les changements de texte
                });
            }

            // Nettoie l'observateur lorsque le composant est démonté
            return () => observer.disconnect();
        }

    }, [autoScrollState]);


    useEffect(() => {
        const parseContext = async () => {
            try {
                const parsed = await marked.parse(project.context);
                const purified = DOMPurify.sanitize(parsed);
                setMarkedContext(purified);
                console.log(purified);
            } catch (e) {
                console.log(e);
                return;
            }
        }
        parseContext();

    }, [project])

    return (
        <div ref={scrollRef} onScroll={() => {
            if (autoScrollState)
                setAutoScrollState(false)
        }}
            className="project_card_preview_container"
            style={{...style}}
        >
            {
                typewriting ?
                    <TypewriterEffect text={memoizedMarkup.__html} delay={delay} /> :
                    <span dangerouslySetInnerHTML={memoizedMarkup}></span>
            }
        </div>
    )
}