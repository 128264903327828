import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../state/hooks';
import { loadProjects } from '../projectsSlice';
import { Footer } from './Footer';
import { Header } from './Header';
import { ProjectsDisplay } from './ProjectsDisplay';
import './ProjectsPage.scss';
import { SearchBar } from './SearchBar';
import TechStackOverview from './TechStack/TechStackOverview';

export type projectType = {
  id: number;
  title: string;
  stack: {
    title: string;
    link?: string;
  }[];
  previewUrl: string;
  context: string;
  videoUrl: string;
  gitUrl: string;
}
export type StackItem = {
  title: string;
  link?: string;
};

export const ProjectsPage = () => {
  const projectStore = useAppSelector((state) => state.projectStore);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadProjects());
  }, [dispatch])

  return (
    <div
      className='projects_page_main_content'
    >
      <Header scrollTo='presentation_header' />
      <SearchBar projects={projectStore.projects} />
      <ProjectsDisplay
        projectList={projectStore.projects}
        setFocusedProject={() => null}
      />
      <TechStackOverview />
      <Footer />
    </div>
  )
}
