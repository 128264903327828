
interface CompanyLinkProps {
    title: string;
    link: string;
}

export const CompanyLink = ({ title, link }: CompanyLinkProps) => {
    return (
        <a href={link} target="_blank" rel="noopener noreferrer" style={{
            all: 'unset',
            color: 'inherit',
            textDecoration: 'none',
            cursor: 'pointer',
        }}>{title.toUpperCase()}</a>
    )
}
