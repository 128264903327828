import { useEffect, useState } from "react";

interface TypeWriterProps {
    text: string;
    writingSpeed: string;
    animationDelay: string;
    done: boolean;
    setDone: React.Dispatch<React.SetStateAction<boolean>>
}

export const TypeWriterPrint = ({ text, writingSpeed, animationDelay, done, setDone }: TypeWriterProps) => {
    const [textState, setTextState] = useState('');
    const [textIndex, setTextIndex] = useState(0);
    const [started, setStarted] = useState(false);


    useEffect(() => {
        if (!done) {
            if (!started) {
                setTimeout(() => {
                    setStarted(true);
                }, parseInt(animationDelay, 10));
            }
        }
    }, [text, done, started, animationDelay]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (textIndex <= text.length) {
                setTextState(text.slice(0, textIndex));
                setTextIndex(textIndex + 1);
            } else {
                setDone(true);
            }
        }, parseInt(writingSpeed, 10));
        return () => clearInterval(interval);
    });

    return (
        <>
            {textState}
        </>
    )
}