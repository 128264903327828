import { useEffect, useState } from "react";

interface TypeWriterProps {
    text: string;
    animationDelay: string;
    writingSpeed: string;
    done: boolean;
    setDone: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TypeWriterErase = ({ text, animationDelay, writingSpeed, done, setDone }: TypeWriterProps) => {
    const [textState, setTextState] = useState(text);
    const [started, setStarted] = useState(false);
    const [textIndex, setTextIndex] = useState(text.length);

    useEffect(() => {
        if (!started) {
            setTimeout(() => {
                setStarted(true);
            }, parseInt(animationDelay, 10));
        }
    }, [text, started, animationDelay, done, setDone]);

    useEffect(() => {
        if (started && !done) {
            const interval = setInterval(() => {
                if (textIndex > 0) {
                    setTextState(text.slice(0, textIndex - 1));
                    setTextIndex(textIndex - 1);
                } else {
                    setDone(true);
                }
            }, parseInt(writingSpeed, 10));
            return () => clearInterval(interval);
        }
    }, [text, textIndex, started, writingSpeed, done, setDone]);


    return (
        <>
            {textState}
        </>
    );
}