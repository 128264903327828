import './OkButton.scss';

interface OkButtonProps {
    displayCondition?: boolean;
    onClickHandler: () => void;
    children: React.ReactElement | string;
}

export const OkButton = ({ displayCondition=false, onClickHandler, children }: OkButtonProps) => {

    return (

        <>
            {displayCondition === true ?
                <div
                    className='submit-button'
                    onClick={() => onClickHandler()}
                >
                    <span>
                        {children}
                    </span >
                </div> : null}
        </>
    );
}