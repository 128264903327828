import { useEffect, useMemo, useState } from "react";
import { TypeWriter } from "./TypeWriterJs";

interface TypeWriterListProps {
    words: string[];
    animationDelay?: string;
    repeat?: boolean;
    erase?: boolean;
    delayBeforeErasing?: string;
    promptCursor?: string;
    writingSpeed?: string;
};

interface PrintedWords {
    text: string;
    animationDelay?: string;
    writingSpeed?: string;
}
//Composant qui wrap TypeWriterJs pour afficher une liste de mots les uns après les autres
export const TypeWriterList = ({ words, animationDelay = '0', repeat, erase = true, delayBeforeErasing = '0', promptCursor = '', writingSpeed = '50' }: TypeWriterListProps) => {
    const wordsToPrint: PrintedWords[] = useMemo(() =>
        words.map((word: string, id: number) => ({
            text: word,
            animationDelay: '1000',
            writingSpeed: '50'
        })) || [{
            text: 'Hello',
            animationDelay: '1000',
            writingSpeed: '50'
        }], [words]);
    const [word, setWord] = useState(wordsToPrint[0]);
    const [wordIndex, setWordIndex] = useState(0);
    const [shouldStart, setShouldStart] = useState(false);
    const [done, setDone] = useState(false);

    //shouldStart determines the delay before calling typewriter. It makes sure words are loaded in order.
    //This is why we set it to false, each time a word has finished being printed.
    // When the last word has been printed, setWordIndex is set to 0
    const handleOnComplete = () => {
        if (wordIndex === wordsToPrint.length - 1) {
            if (repeat)
                setWordIndex(0);
            else
                setDone(true)
            return;
        }
        setShouldStart(false);
        setWordIndex((prev) => prev + 1);
    }

    useEffect(() => {
        setWord(wordsToPrint[wordIndex]);
    }, [wordIndex, wordsToPrint]);

    useEffect(() => {
        if (!shouldStart && !done) {
            const timeout = setTimeout(() => {
                setShouldStart(true);
            }, parseInt(animationDelay, 10))
            return () => {
                clearTimeout(timeout);
            }
        }
    })
    return (
        <>
            {shouldStart &&
                (
                    <TypeWriter
                        text={word.text}
                        animationDelay={word.animationDelay}
                        erase={erase && (wordIndex < wordsToPrint.length - 1 || repeat)}
                        onComplete={handleOnComplete}
                        delayBeforeErasing={delayBeforeErasing}
                        writingSpeed={writingSpeed}
                    />
                )
            }
            <span className="typewriterjs_blinking_cursor"> {promptCursor} </span>
        </>
    );
}