import './Presentation.scss';
import { PresentationHeader } from './PresentationHeader/PresentationHeader';
import { PresentationResume } from './PresentationResume/PresentationResume';

type PresentationProps = {
    scrollTo: string;
}

export const Presentation = ({ scrollTo }: PresentationProps) => {

    return (
        <div className="presentation_container">
            <PresentationHeader scrollTo={scrollTo} />
            <PresentationResume />
        </div >
    )
};
