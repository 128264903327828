import { YouTube } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../state/hooks';
import { ProjectCardPreview } from '../../../layout/Project/ProjectCardPreview/ProjectCardPreview';
import { projectType } from '../ProjectsPage/ProjectsPage';
import { SocialNetwork } from '../ProjectsPage/SocialNetwork';
import { notFound } from '../projectsSlice';
import './ProjectPage.scss';
import { ProjectPageFooter } from './ProjectPageFooter';
import { ProjectPageHeader } from './ProjectPageHeader';

const defaultProject = notFound;

export const ProjectPage = () => {
  const { projectTitle: param = '' } = useParams();
  const projectStore = useAppSelector((state) => state.projectStore);

  const findProjectByTitle = (projects: projectType[], title: string) => {
    const titleToLower = title.toLowerCase();
    const project = projects.find((project: projectType) => project.title.toLowerCase().includes(titleToLower));
    return project || defaultProject;
  }

  const project = findProjectByTitle(projectStore.projects, param);

  const scrollDownTo = (ref: string) => {
    const elem = document.getElementById(ref);
    elem?.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div className='project_page_container'>
      <ProjectPageHeader project={project} />
      <div style={{
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <div className='project_page_preview_text'>
          <ProjectCardPreview
            project={project}
            delay={10}
            typewriting
            style={{
              maxWidth: '100vw',
              minWidth: '80vw',
              maxHeight: '50vh',
              flex: 1,
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
            <div className='project_video_item_github-link'>
              GitHub
              <SocialNetwork
                iconName={'github'} redirectUrl={project.gitUrl} />
            </div>
            <div
              className='project_video_item_github-link'
              onClick={() => scrollDownTo('footer')}
            >
              Démo
              <YouTube
                height="1em"
                width="1em"
              />
            </div>
          </div>
        </div>
      </div>
      <div id='footer' style={{
        flex: 1,
        minHeight: '100%'
      }}>
        <ProjectPageFooter
          project={project}
          style={{
            paddingTop: '1rem'
          }} />
      </div>
    </div>
  )
}
