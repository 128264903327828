import type {
    AnimationNameWrapper,
    Children,
    ReactAnimationsCSSProperties
} from '../../../types';
import './FadeIn.scss';

interface Props {
    children: Children;
    animationClassName?: AnimationNameWrapper;
    animationOptions?: ReactAnimationsCSSProperties;
}

export const FadeIn = ({
    children,
    animationClassName = 'elem_fade_in',
    animationOptions = {}
}: Props) => {

    const animationAttributes = {
        animationDuration: '2s',
        animationDelay: '0ms',
        animationFillMode: 'forwards',
        ...animationOptions
    }
    return (
        <div
            className={`${animationClassName}`}
            style={{ ...animationAttributes }}>
            {children}
        </div >
    );
}
