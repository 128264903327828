import { useNavigate } from 'react-router-dom';
import { navIcons } from '../../../../iconPaths';
import { projectType } from '../ProjectsPage/ProjectsPage';
import './ProjectPageHeader.scss';

type Props = {
  project: projectType
}

export const ProjectPageHeader = ({ project }: Props) => {
  let navigate = useNavigate();

  return (
    <div className='project_page_header'>
      <div className='project_page_social-network_container'>
        {navIcons['keyboardBackspace']({
          className: 'social-network_button_svg',
          focusable: 'false',
          'aria-hidden': 'true',
          onClick: () => navigate('/'),
        }
        )}
      </div>
      <span className='project_page_header_title project_page_header_menu-item'>
        {project.title}
      </span>
      <div className='project_page_stack project_page_header_menu-item'>
        {project.stack.map((tech: { title: string; link?: string }, id: number) => (
          <a href={tech.link} key={'header/stack' + tech.link} style={{ textDecoration: 'none', flex: 1, textAlign: 'center' }}>
            {tech.title}
          </a>))}
      </div>
    </div>
  )
}
