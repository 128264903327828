import { contactIcons } from '../../../../iconPaths';
import './SocialNetwork.scss';

type Props = {
  redirectUrl: string;
  text?: string;
  iconName: keyof typeof contactIcons;
  style?: React.CSSProperties;
}

export const SocialNetwork = ({ iconName, redirectUrl, text = '', style = {} }: Props) => {

  const EmptyElement = <></>;

  const SvgElement = contactIcons[iconName]({
    className: 'social-network_button_svg',
    focusable: 'false',
    'aria-hidden': 'true',
  }) ?? EmptyElement;

  return (
    <div
      style={{ ...style }}
    >
      <button
        className='social-network_button'
        onClick={() => window.location.href = redirectUrl}
        type='button'
      >
        {SvgElement}
        {text}
      </button>
    </div >
  )
}
