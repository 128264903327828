import { FadeIn } from "../../../../../animated/FadeIn/FadeIn";
import { TypeWriter } from "../../../../../animated/TypeWriter/TypeWriterJs";
import { TypeWriterList } from "../../../../../animated/TypeWriter/TypeWriterList";
import { Delay } from "../../../../../utils/Delay";
import './PresentationAnchor.scss';

interface PresentationAnchorProps {
    scrollTo: string;
}

export const PresentationAnchor = ({ scrollTo }: PresentationAnchorProps) => {
    const specialisations = [
        "web",
        "back-end",
        "front-end",
        "d'API",
        "d'interfaces",
        "d'applications",
    ];

    const scrollDownTo = () => {
        const elem = document.getElementById(scrollTo);
        elem?.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div className='presentation_anchor-to-project'>
            <FadeIn animationOptions={{
                animationDuration: '1s',
            }}>
                <TypeWriter text="Olivier Laffon" writingSpeed="71" />
            </FadeIn>
            <FadeIn animationOptions={{
                animationDelay: '1s',
                animationDuration: '1s',
            }}>
                <div className="presentation_anchor_specialization">
                    {'{'}
                    <span>
                        <Delay delay={2000}>
                            <TypeWriter animationDelay="2000" writingSpeed="100" text={'Développement '} promptCursor="|" />
                        </Delay>
                    </span>
                    <span>
                        <Delay delay={3500}>
                            <TypeWriterList words={specialisations} writingSpeed="200" repeat={true} delayBeforeErasing="7000" promptCursor="|" />
                        </Delay>
                    </span>
                    {'}'}
                </div>
            </FadeIn>
            <FadeIn animationOptions={{
                animationDuration: '2s',
                animationDelay: '4s',
            }}>
                <span
                    className='presentation_anchor_button'
                    onClick={scrollDownTo}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height='auto' width='1em' viewBox='0 0 100 100'>
                        <polygon
                            fillRule="evenodd"
                            points="10,50, 90,50 50,90"
                        />
                    </svg>
                    <TypeWriter animationDelay="4000" writingSpeed="28" text="Boîte à outils" />
                </span>
            </FadeIn>
        </div>

    )
}