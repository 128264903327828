import { Dispatch, SetStateAction } from 'react';
import { projectType } from '../../pages/Projects/ProjectsPage/ProjectsPage';
import { ProjectCardScreenshot } from './PojectCardScreenshot/ProjectCardScreenshot';
import './ProjectCard.scss';
import { ProjectCardPreview } from './ProjectCardPreview/ProjectCardPreview';
import { ProjectCardStack } from './ProjectCardStack/ProjectCardStack';
import { ProjectCardTitle } from './ProjectCardTitle/ProjectCardTitle';

type Props = {
  project: projectType;
  select: Dispatch<SetStateAction<projectType>>;
  typewriting?: boolean;
  autoScroll?: boolean;
}

export const ProjectCard = ({ project, select, typewriting = false, autoScroll = false }: Props) => {

  return (
    <div
      onMouseEnter={() => select(project)}
      className='project_card_grid_container'
    >
      <ProjectCardTitle project={project} />
      <div className='project_card_grid_screenshot_container_wrapper'>
        <ProjectCardScreenshot project={project} />
        <div>
          <ProjectCardPreview project={project} typewriting={typewriting} autoScroll={autoScroll} />
          <ProjectCardStack project={project} />
        </div>
      </div>
    </div>
  )
}
