
import React from 'react'
import { TypewriterEffect } from '../../../../../animated/TypeWriter/TypewriterEffect';



interface PresentationResumeItemProps {
    title: string;
    contextComponent: React.ReactElement<HTMLElement>;
}

export const PresentationResumeItem = ({ title, contextComponent }: PresentationResumeItemProps) => {
    const [showContext, setShowContext] = React.useState(true);
    const [showInitialText, setShowInitialText] = React.useState(true);

    const ref = React.useRef<HTMLDivElement>(null);

    return (
        <div className="presentation_resume_item">
            <div hidden ref={ref}>
                {contextComponent}
            </div>
            <div>
                {ref.current &&
                    (!showContext ?
                        <h1>
                            <span className='presentation_resume_item_plus' onClick={() => setShowContext(true)}> + </span>{`{${title}}`}
                        </h1> :
                        <>
                            <h1 className='presentation_resume_item_plus' onClick={() => setShowContext(false)}> - {`{`}
                            </h1>
                            {!showInitialText ?
                                <TypewriterEffect delay={10} text={ref.current?.innerHTML} setDone={setShowInitialText} /> :
                                <span dangerouslySetInnerHTML={{ __html: ref.current?.innerHTML }}></span>}
                            <h1>{`}`}</h1>
                        </>
                    )
                }
            </div>
        </div>
    )
}