import { useEffect } from 'react';
import { techIcons } from '../../../iconPaths';

interface StackButtonProps {
    svgProps?: React.SVGProps<SVGSVGElement>,
    technoTitle: keyof typeof techIcons;
    onClickHandler: () => void;
}

export const StackButton = ({ svgProps, technoTitle, onClickHandler }: StackButtonProps) => {

    useEffect(() => { console.log('ici', technoTitle) }, [technoTitle])

    const SvgElement = techIcons[technoTitle] && techIcons[technoTitle]({
        viewBox: '0 0 24 24',
        fill: 'currentColor',
        height: '1em',
        width: '1em',
        onClick: onClickHandler,
        cursor: 'pointer',
        ...svgProps

    });
    return (
        <>
            {SvgElement}
        </>
    )
    /*
    return (
        (techIcons[technoTitle] as Function) ({
            viewBox: '0 0 34 32',
            fill: 'currentColor',
            height: '1em',
            width: '1em',
            ...svgProps
        })
    );

*/
}