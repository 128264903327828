import { Item } from "./common/Section/Item";
import { Section } from "./common/Section/Section";
import { AUTOENTREPRISE } from "./Items/AUTOENTREPRISE";
import { AUTOENTREPRISE2 } from "./Items/AUTOENTREPRISE2";
import { PMU } from "./Items/PMU";
import { WEMANITY } from "./Items/WEMANITY";


export const Experience = () => {
    return (
        <>
            <Section title='EXPERIENCE'>
                <Item>
                    <AUTOENTREPRISE2 />
                </Item>
                <Item>
                    <PMU />
                </Item>
                <Item>
                    <WEMANITY />
                </Item>
                <AUTOENTREPRISE />
            </Section>
        </>
    );
}
