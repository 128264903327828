import { CompanyDescription } from "../common/Company/CompanyDescription"
import { CompanyLink } from "../common/Company/CompanyLink"
import { DevelopedFeatures } from "../common/Company/DevelopedFeatures"
import { ItemTitle } from "../common/Section/ItemTitle"

export const AUTOENTREPRISE2 = () => {
    return (
        <>
            <ItemTitle>Développeur indépendant - TypeScript, Puppeteer</ItemTitle>
            <CompanyDescription
                name={<CompanyLink title='AUTO-ENTREPRENEUR' link='https://www.olivier-laffon.com' />}
                date='2023 2024 - 1 an'
                location='Saint-Cloud'
            >
                En tant qu'auto entrepreneur, j'apporte des solutions techniques aux
                problèmes rencontré par les clients.
            </CompanyDescription>
            <DevelopedFeatures>
                <span>
                    Aggregation de données clés pour une solution de mise en relation de chauffeurs spécialisés (Puppeteer)
                </span>
                <span>
                    Mise en place de stratégies automatisées pour récupérer et traiter des données web structurées
                </span>
                <span>
                    Mise en place des scripts automatisés pour naviguer à travers des pages dynamiques,
                    extraire des données pertinentes, et les stocker dans des formats adaptés (JSON, CSV, base de données)
                </span>
                <span>
                    Filtrage, transformation et aggrégation des données vers un format exploitable par le client (Excel)
                </span>
                <span>
                    Respect des règles de conformité et de l'éthique du scraping, avec gestion des contraintes de sécurité (headers, délais de requêtes, etc.)
                </span>
            </DevelopedFeatures>
        </>)
}
