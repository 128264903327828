import React from 'react'

interface SectionTitleProps {
    children: React.ReactNode;
}

export const ItemTitle = ({ children }: SectionTitleProps) => {
    return (
        <div style={{
            fontFamily: 'Sans-Serif, Helvetica',
            fontSize: '1.2em',
            paddingBottom: '0.5em',
        }}>
            {children}
        </div>
    )
}
