import { PresentationAnchor } from "./PresentationAnchor";
import './PresentationHeader.scss';
import { PresentationImage } from "./PresentationImage";

interface PresentationHeaderProps {
    scrollTo: string;
}

export const PresentationHeader = ({scrollTo}: PresentationHeaderProps) => {
    return (
        <div className="presentation_header_container">
            <PresentationImage />
            <PresentationAnchor scrollTo={scrollTo} />
        </div>

    )
}