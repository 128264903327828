import { useEffect } from "react";
import Typewriter from "typewriter-effect";
import "./TypewriterEffect.scss";

interface TypewriterEffectProps {
    text: string;
    delay?: number;
    setDone?: (done: boolean) => void;
}


export const TypewriterEffect = ({ text, delay = 75, setDone = () => { } }: TypewriterEffectProps) => {

    useEffect(() => {
        return (
            () => {
                setDone(true);
            }
        )
    }, [setDone]);

    return (
        <div>
            {text && (
                <Typewriter
                    options={{
                        delay: delay,
                        cursor: '',
                    }}
                    onInit={(typewriter) => {
                        typewriter.typeString(text)
                            .start()
                            .callFunction(() => {
                                setDone(true);
                            }, [setDone])
                    }}

                />
            )}

        </div>
    )
}