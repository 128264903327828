import { useEffect, useState } from "react";
import { TypeWriterErase } from "./TypeWriterErase";
import { TypeWriterPrint } from "./TypeWriterPrint";
import './TypeWriterJs.scss';

interface TypeWriterProps {
    text: string;
    animationDelay?: string;
    writingSpeed?: string;
    delayBeforeErasing?: string;
    erase?: boolean;
    promptCursor?: string;
    onComplete?: () => void
}

export const TypeWriter = ({
    text,
    animationDelay = '0',
    writingSpeed = '50',
    delayBeforeErasing = '1000',
    erase = false,
    onComplete,
    promptCursor = '',
}: TypeWriterProps) => {

    const [printDone, setPrintDone] = useState(false);
    const [eraseDone, setEraseDone] = useState(false);

    useEffect(() => {
        setEraseDone(false);
        setPrintDone(false);
    }, [text]);

    useEffect(() => {
        if (eraseDone) {
            if (onComplete)
                onComplete();
        }
    }, [eraseDone, onComplete]);

    useEffect(() => {
        if (printDone && !erase) {
            if (onComplete)
                onComplete();
        }
    })

    return (
        <>
            {!printDone ?
                (<>
                    <TypeWriterPrint
                        text={text}
                        writingSpeed={writingSpeed}
                        animationDelay={animationDelay}
                        done={printDone}
                        setDone={setPrintDone}
                    /> <span className='typewriterjs_blinking_cursor'>{promptCursor}</span>
                </>) : (erase ?
                    (
                        <>
                            <TypeWriterErase
                                text={text}
                                animationDelay={(parseInt(animationDelay, 10) + parseInt(delayBeforeErasing, 10)).toString()}
                                writingSpeed={writingSpeed}
                                done={eraseDone}
                                setDone={setEraseDone}
                            /> <span className='typewriterjs_blinking_cursor'>{promptCursor}</span>
                        </>) : <>{text}</>)
            }
        </>
    )
}