import { StackButton } from "../../../navigation/StackButton/StackButton";
import { StackItem, projectType } from "../../../pages/Projects/ProjectsPage/ProjectsPage";
import './ProjectCardStack.scss';


interface ProjectCardStackProps {
    project: projectType;
}

export const ProjectCardStack = ({ project }: ProjectCardStackProps) => {
    const parseTitle = (title: string) => title.toLowerCase().split(' ')[0];

    const ProjectStackButtonClickHandler = (link: string | undefined) => window.location.href = (link ?? './');

    return (
        <div className='project_card_item_stack_container'>
            {project.stack.map((techno: StackItem, id: number) =>
                <div
                    className='project_card_item_stack_item'
                    key={project.id + '/stack/' + id + '/' + techno}
                >
                    <StackButton
                        technoTitle={parseTitle(techno.title)}
                        onClickHandler={() => ProjectStackButtonClickHandler(techno.link)} />
                    <a href={
                        techno.link ?? '#'
                    }>{techno.title}</a>
                </div>)}
        </div>
    )
}