import { configureStore } from '@reduxjs/toolkit'
import { reducer } from '../components/pages/Projects/projectsSlice';

export const store = configureStore({
  reducer: {
    projectStore: reducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
