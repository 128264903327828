interface CompanyDescriptionProps {
    name: React.ReactNode;
    date: React.ReactNode;
    location: React.ReactNode;
    children?: React.ReactNode;
}

export const CompanyDescription = ({ name, date, location, children }: CompanyDescriptionProps) => {
    return (
        <div>
            <div>
                <div className="section-title" style={{ fontWeight: 'bolder' }}>{name}</div>
                <div style={{ display: "flex", alignItems: 'center', gap: '0.5em' }}>
                    <div style={{ display: "flex", justifyItems: 'center' }}>
                        <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em">
                            <path d="M7 11h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zm0 4h2v2h-2zm4-4h2v2h-2zm0 4h2v2h-2zM5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM19 8l.001 12H5V8h14z" />
                        </svg>
                        <span>
                            {date}
                        </span>
                    </div>
                    <div>
                        <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em">
                            <path d="M5.64 16.36a9 9 0 1112.72 0l-5.65 5.66a1 1 0 01-1.42 0l-5.65-5.66zm11.31-1.41a7 7 0 10-9.9 0L12 19.9l4.95-4.95zM12 14a4 4 0 110-8 4 4 0 010 8zm0-2a2 2 0 100-4 2 2 0 000 4z" />
                        </svg>
                        {location}
                    </div>
                </div>
            </div>
            <div style={{ fontFamily: 'Sans-Serif, Roboto' }}>

                {children && <p>{children}</p>}
            </div>
        </div>
    );
}


