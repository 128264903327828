import { useEffect, useState } from "react";

interface DelayProps {
    delay: number
    children: React.ReactNode
}

export const Delay = ({ delay, children }: DelayProps) => {
    const [done, setDone] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDone(true);
        }, delay);
        return () => clearTimeout(timeout);
    }, [delay]);

    return (
        <>
            {done && children}
        </>
    )
}
