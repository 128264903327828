import { FilterOptionsState } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ForwardedRef, forwardRef, useMemo, useRef, useState } from 'react';
import { simpleMatch } from '../../../utils';
import { projectType } from '../../pages/Projects/ProjectsPage/ProjectsPage';
import { ClickAwayListener } from '../../utils/ClickAwayListener';
import { ClearIcon } from './ClearIcon';
import './CustomAutocomplete.scss';
import LabelWrapper from './LabelWrapper';

export interface CustomAutocompleteProps {
    projects: projectType[];
    handleAutocompleteChange: (value: { label: string; }) => void;
    handleAutocompleteValidation: (key: React.KeyboardEvent<HTMLDivElement>) => void;
}

export const CustomAutocomplete = forwardRef(({ projects, handleAutocompleteChange, handleAutocompleteValidation }: CustomAutocompleteProps, ref: ForwardedRef<HTMLDivElement>) => {

    const [isFocused, setIsFocused] = useState(false); // Track focus state

    const handleFocus = () => {
      setIsFocused(true);
    };
  
    const handleBlur = () => {
      setIsFocused(false);
    };
    //constants
    const colors = {
        pureWhite: '#FFFFFF',
        black: '#212121',
        red: '#FF0000',
    }
    const color = {
        searchBar: colors.pureWhite,
        searchBarError: colors.red,
        textField: colors.black,
    }
    const labelDefaultValue = 'Recherchez un projet';
    const labelFocusValue = [
        'typescript...',
        'RFP...',
        'Ember.js...',
        'React...',
        'October...',
        'Recherchez un projet'];
    const labelConfirmValue = "Appuyer sur 'Entrer'";

    //state vars
    const projectSearchList = useMemo<{ label: string }[]>(() => {
        return projects.map((project: projectType) => ({ label: project.title }))
    }, [projects]);
    const [label, setLabel] = useState<string>(labelDefaultValue);
    const [borderColor, setBorderColor] = useState<string>(color.searchBar);
    const searchBarRef = useRef<HTMLDivElement>(null);

    const handleFilterOptions = (
        options: { label: string }[],
        state: FilterOptionsState<{ label: string }>
    ) => {
        const inputStartsWith = (fixedValue: string) => fixedValue.toLowerCase().startsWith(state.inputValue.toLowerCase());
        const inputMatches = (fixedValue: string) => simpleMatch(fixedValue, state.inputValue);

        const newOptions = projects.map((project: projectType) => {
            let newOptionValue: { label: string } = { label: '' };

            project.stack.forEach((projectStack) => {
                if (state.inputValue.length && inputMatches(projectStack.title)) {
                    newOptionValue.label = `${project.title}`;
                }
            })

            if (!newOptionValue.label.length && inputStartsWith(project.title)) {
                newOptionValue.label = `${project.title}`
            }
            console.log(newOptionValue);
            return newOptionValue;
        })
            .filter((project: { label: string }) => project.label.length);
        setBorderColor(newOptions.length ? color.searchBar : color.searchBarError);
        return newOptions;
    }

    const sxStyles = {
        autoComplete: {
            backgroundColor: color.textField,
            '& .MuiAutocomplete-popupIndicator > .MuiSvgIcon-root': {
                color: borderColor
            },
            input: {
                color: color.searchBar
            },
            label: {
                color: color.searchBar,
                "&.Mui-focused": {
                    color: borderColor
                },
            },
            "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                    borderWidth: '2px',
                    borderColor: borderColor,
                },
                "&.Mui-focused fieldset": {
                    borderColor: borderColor,
                },
                fieldset: {
                    borderColor: borderColor,
                },
            },
        }
    }

    return (
        <div className='autocomplete_container' ref={ref}>
            <Autocomplete
                disablePortal
                options={projectSearchList}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                filterOptions={handleFilterOptions}
                clearIcon={<ClearIcon className='autocomplete_clear_icon' />}
                renderInput={(params) =>
                    <ClickAwayListener ref={searchBarRef} onClickAway={() => setBorderColor(color.searchBar)}>
                        <TextField
                            {...params}
                            variant='outlined'
                            autoComplete='true'
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            label={<LabelWrapper defaultLabel={label} focusedLabel={labelFocusValue} isFocused={isFocused} />}
                            sx={sxStyles.autoComplete}
                            onChange={() => setBorderColor(color.searchBar)}
                            size='medium'
                        />
                    </ClickAwayListener>}
                onChange={(event, value) => {
                    if (value?.label) {
                        handleAutocompleteChange(value);
                        setLabel(labelConfirmValue);
                        console.log(labelConfirmValue, ' should be the new label');
                        console.log(label, ' is the current label');
                    } else {
                        console.log('We have no value.label, ', value);
                        handleAutocompleteChange({ label: '' });
                        setLabel(labelDefaultValue);
                    }
                }}
                onKeyDown={(key: React.KeyboardEvent<HTMLDivElement>) => handleAutocompleteValidation(key)}
            />
        </div>
    );
});