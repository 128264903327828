import { TypeWriterList } from "../../animated/TypeWriter/TypeWriterList";

const LabelWrapper = ({ defaultLabel, focusedLabel, isFocused }: { defaultLabel: string, focusedLabel: string[], isFocused: boolean }) => {    
    return (
      <>
        { isFocused ? <TypeWriterList words={focusedLabel} /> : defaultLabel}
      </>
    );
  };
  
  export default LabelWrapper;