import openToWork from './open-to-work.jpeg';

export const PresentationImage = () => {
    return (
        <div
            style={{
                flex: 1,
            }}
        >
            <img style={{
                borderRadius: '0 100% 100% 0',
                height: '100%',
            }} src={openToWork} alt='Profile taken from linkedin' />
        </div>
    )
}