import { Experience } from "./Experience/Experience";
import { PresentationResumeItem } from "./PresentationResumeItem";
import './PresentationResume.scss'

export const PresentationResume = () => {
    return (
        <div className="presentation_resume_container">
            <div className='presentation_resume_item'>
                <PresentationResumeItem title="Expérience" contextComponent={<Experience />} />
            </div>
        </div >
    )
}