import { useNavigate } from "react-router-dom";
import { projectType } from "../../../pages/Projects/ProjectsPage/ProjectsPage";
import './ProjectCardScreenshot.scss';

interface ProjectCardScreenshotProps {
    project: projectType;
}

export const ProjectCardScreenshot = ({ project }: ProjectCardScreenshotProps) => {
    let navigate = useNavigate();

    const handleProjectClick = () => {
        navigate("/projects/" + project.title);
    }

    return (
        <div className="project_card_screenshot_container" style={{
        }}>
            <div
                style={{
                    marginInlineEnd: '1rem',
                    cursor: 'pointer',
                    background: `url(${project.previewUrl}) center / cover no-repeat`
                }}
                onClick={handleProjectClick}
            />
        </div>
    );
};