import { gitHubUrl, linkedInProfileUrl, mailToUrl } from '../../../../urls';
import './Footer.scss';
import { SocialNetwork } from './SocialNetwork';

export const Footer = () => {
    const scrollUpTo = () => {
        const elem = document.getElementById('root');
        elem?.scrollIntoView({ behavior: 'smooth' });
    }
    return (
        <div
            className='projects_page_footer'
        >
            <span
                className='presentation_anchor_button'
                onClick={scrollUpTo}
            >
                <svg xmlns="http://www.w3.org/2000/svg" height='auto' width='1em' viewBox='0 0 100 100'>
                    <polygon
                        fillRule="evenodd"
                        points="10,50 90,50 50,10"
                        color='white'
                    />
                </svg>
            </span>
            <SocialNetwork
                iconName={'github'}
                redirectUrl={gitHubUrl}
                text='GitHub'
            />
            <SocialNetwork
                iconName={'linkedin'}
                redirectUrl={linkedInProfileUrl}
                text='LinkedIn'
            />
            <SocialNetwork
                iconName={'email'}
                redirectUrl={mailToUrl}
                text='Contact'
            />
        </div>
    )
}