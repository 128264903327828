import { Dispatch, SetStateAction } from 'react';
import { ProjectCard } from '../../../layout/Project/ProjectCard';
import './ProjectsDisplay.scss';
import { projectType } from './ProjectsPage';

type Props = {
  projectList: projectType[];
  setFocusedProject: Dispatch<SetStateAction<projectType>>;
}

export const ProjectsDisplay = ({ projectList, setFocusedProject }: Props) => {
  return (
    <div className='projects_display_container'>
      {projectList.map((project: projectType, id: number) =>
        <ProjectCard key={'project/' + id + '/' + project.title} project={project} select={setFocusedProject} />)}
    </div>
  )
}
