import React from 'react'

interface SectionProps {
    title: string;
    children: React.ReactNode;
}

export const Section = ({ title, children }: SectionProps) => {
    return (
        <div style={{marginInline: '2em'}}>
            <h3 style={{ position: 'relative', borderBottom: '3px solid currentColor', paddingBlockEnd: '0em', marginBlockEnd: '0.1em' }}>
                {title}
            </h3>
            <div style={{ paddingTop: '5px', columnGap: '20em' }}>
                {children}
            </div>
        </div>
    )
}


