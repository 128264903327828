import './ProjectCardTitle.scss';
import { projectType } from "../../../pages/Projects/ProjectsPage/ProjectsPage";

interface ProjectCardTitleProps {
    project: projectType;
}

export const ProjectCardTitle = ({project}: ProjectCardTitleProps) => {
    return (
        <p className='project_card_item_title'>
            Projet {project.title}
        </p>
    );
}
