import React from 'react';
import { ProjectVideo } from '../../../layout/Project/ProjectVideo';
import { projectType } from '../ProjectsPage/ProjectsPage';
import './ProjectPageFooter.scss';
type Props = {
  project: projectType;
  style?: React.CSSProperties;
}

export const ProjectPageFooter = ({ project, style = {} }: Props) => {
  return (
    <div className='project_page_footer' style={{ ...style }}>
      <ProjectVideo project={project} />
    </div>
  )
}
