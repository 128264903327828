import React from 'react'

interface DevelopedFeaturesProps {
    children: React.ReactNode
}

export const DevelopedFeatures = ({ children }: DevelopedFeaturesProps) => {
  return (
    <ul>
      {React.Children.map(children, (child, id) => (
        <li key={id}>{child}</li>
      ))}
    </ul>
  )
}

